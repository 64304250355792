
@import '../../constants//colorconstants.scss';

$border-radius: 10px;
.custom-button{
    width: 100px;
    padding: 10px;
    background-color: $primary-color;
    color: white;
    text-align: center;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color 0.3s ease;


    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px;
    }
    &:hover {
        background-color: darken($primary-color, 10%);
    }
}