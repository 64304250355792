@import "../../constants/colorconstants.scss";
@import "../../constants/fontcontants.scss";
@import "../../constants/constantsize.scss";

.navbar {
  background-color: #282828 !important;
  color: #fff !important;
  padding: 0.5% 0;
  transition: all 0.3s ease-out;

  .brand {
    object-fit: contain;
    width: auto;
    height: 3rem;
    &:hover {
      cursor: pointer;
    }

    @media (max-width: 960px) {
      height: 2rem;
    }
  }

  .nav-links {
    display: flex;
    align-items: center;

    @media (max-width: 960px) {
      display: none;
    }
  }

  .nav-link {
    color: inherit;
    text-decoration: none;
    margin-right: 15px;
    position: relative;
    padding-bottom: 5px;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 2px;
      background-color: transparent;
      width: 0;
      transition: all 0.3s ease-in-out;
      transform: translateX(-50%);
    }

    &:hover {
      &::after {
        width: 80%;
        background-color: #7cb342;
      }
    }
  }

  .active {
    &::after {
      width: 80%;
      background-color: #7cb342;
    }
  }

  .go-to-app {
    background-color: $primary-color !important;
    color: white !important;
  }

  .expanded-menu {
    max-height: 0; // Start with height 0
    opacity: 0; // Start with opacity 0
    overflow: hidden; // Hide overflowing content
    transition: max-height 0.5s ease, opacity 0.5s ease; // Transition effects

    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 0;

      .nav-link {
        color: inherit;
        text-decoration: none;
        margin: 5px 0;
        margin-right: 0;

        &:hover {
          transition: ease-in-out 0.5s;
        }
      }

      .go-to-app {
        background-color: $primary-color !important;
        margin: 5px 0;
      }
    }
  }

  .menu-icon {
    @media (min-width: 961px) {
      display: none;
    }
  }
}
