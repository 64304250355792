

@import './constantsize.scss';




.title {
    font-weight: 600;
    font-size: clamp(3rem, 2.5vw + 2rem, 2.5vw); 
    @media (min-width: $tab-min-width) and (max-width: $tab-max-width) {
        font-size: clamp(2.5rem, 2vw + 1rem, 2.92vw); 
    }

    @media (max-width: $mobile-max-width) {
        font-size: clamp(2.5rem, 4vw + 1rem, 1.67vw);
    }

    @media (min-width: $desktop-gaint-screen) {
        font-size: 2.5vw; 
    }
}








.description {
    font-size: clamp($description-mobile, 2vw, $description-desktop-min); // Dynamic between mobile and desktop min size
    line-height: 1.5;

    @media (min-width: $tab-min-width) and (max-width: $tab-max-width) {
        font-size: clamp($description-tab, 1.5vw, $description-desktop-min); // Clamp for tablet
    }

    @media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
        font-size: clamp($description-desktop-min, 1.2vw, $description-desktop-large); // Clamp for standard desktop
    }

    @media (min-width: $desktop-large-min-width )  and (max-width: $desktop-large-max-width) {
        font-size: 18px; 
    }
    @media (min-width: $desktop-gaint-screen) {
        font-size: 20px; 
    }
}