@import '../../constants/colorconstants.scss';


$font-color-dark: #333;
$font-size-large: 2.5rem;
$font-size-small: 1rem;

.not-found {
    height: 70vh; // Full viewport height
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center; 

    &__title {
        font-size: 10vw;
        color: $primary-color;
        margin-bottom: 20px;

        @media screen and (max-width: 600px) {
            font-size: 20vw;
        }
    }

    &__illustration{
        width: 30vw;
    }

    &__message {
        font-size: $font-size-small;
        color: $font-color-dark;
        margin-bottom: 30px;

        @media screen and (max-width: 600px) {
            font-size: $font-size-small;
            margin-bottom: 20px;
        }
    }

    &__link {
        font-size: $font-size-small;
        color: $primary-color;
        text-decoration: none;
        border-bottom: 1px solid $primary-color;
        padding-bottom: 5px;
        transition: color 0.3s, border-bottom-color 0.3s;

        &:hover {
            color: darken($primary-color, 10%);
            border-bottom-color: darken($primary-color, 10%);
        }
    }
}
