@import "../../constants/colorconstants.scss";
@import "../../constants/constantsize.scss";
@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap");

$font-color-dark: #333;
$font-size-large: 2.5rem;
$font-size-small: 1rem;

.coming-soon {
  height: 70vh; 
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  @media (min-width:$tab-max-width) {
    min-height: 500px;
  }
  @media (min-width:$mobile-max-width) {
    min-height: 200px;
  }

  &__title {
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 600;

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

  &__decorative-title {
    color: $primary-color;
    font-size: 5vw;
    margin: 0;
    font-family: "MuseoModerno", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  }

  &__illustration {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__message {
    font-size: $font-size-small;
    color: $font-color-dark;
    margin-bottom: 30px;

    @media screen and (max-width: 600px) {
      font-size: $font-size-small;
      margin-bottom: 20px;
    }
  }

  &__link {
    font-size: $font-size-small;
    color: $primary-color;
    text-decoration: none;
    border-bottom: 1px solid $primary-color;
    padding-bottom: 5px;
    transition: color 0.3s, border-bottom-color 0.3s;

    &:hover {
      color: darken($primary-color, 10%);
      border-bottom-color: darken($primary-color, 10%);
    }
  }
}
