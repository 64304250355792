
@import '../../../constants/constantsize.scss';
@import '../../../constants/colorconstants.scss';
.whyZenithHeroSection {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  justify-content: end;
  width: 90%;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  &__left {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    gap: 3vw;
    @media screen and (max-width: $tab-max-width) {
      align-items: center;
      justify-content: center;
      flex-direction: column; 
    }

  }
  &__right {
    width: 100%;
border-radius: 30px;
border-bottom: 2px dashed $primary-color;
display: flex;
flex-direction: column;
align-items: start;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }

    img {
      height: auto;
      width: 100%;

      @media screen and (max-width: 900px) {
        height: auto;
        border-radius: 20px;

      }
    }

    &__slogan{

      color: $primary-color;

      margin-left: 3%;
      font-weight: bold;

    }
  }
}
