@import '../../constants/colorconstants.scss';


@import '../../constants/constantsize.scss';

.footer {
    width: calc(100% - 10rem);
    padding: 2rem 5rem;
    background-color: #282828;
    align-items: start;
    justify-content: start;
    

    @media (min-width:$tab-min-width) and (max-width:$tab-max-width) {
        width: 90vw;
        padding: 2rem 5vw;
    }
    @media  (max-width:$mobile-max-width) {
        width: 90vw;
        padding: 2rem 5vw;
    }
    &-logo-container{
    width: 100%;
    display: flex;
    justify-content: start;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        border-bottom: 1.5px solid $primary-color;
        padding-bottom: 1rem;


    @media (min-width:$tab-min-width) and (max-width:$tab-max-width) {
       
    }
    @media  (max-width:$mobile-max-width) {
       
        flex-direction: column;
    }
    }

    /* Footer logo styling */
    &__logo {
        height: auto;
        width: 120px;
        object-fit: contain;
        align-items: start;
        justify-content: start;
    }

    /* Layout for left section of the footer */
    &__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;


    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        &-contact {
            display: flex;
            gap: 40px;
        }
    }

    /* Layout for right section of the footer */
    &__right {
        display: flex;
        gap: 30px;
        justify-content: flex-end;
   
        @media  (max-width:$mobile-max-width) {
            justify-content: start;
            gap: 60px;
        }
    }

    /* Footer titles */
    &__title {
        color: white;
        font-weight: bold;
margin-bottom: 8px;
        &--contact {
            color: $primary-color;
        }
    }

    /* Footer text description */
    &__description {
        color: white;
        text-decoration: none;
        margin-top: 8px;
        font-size: 14px;
    }

    /* Quick Links section */
    &__links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            color: white;
            text-decoration: none;

            &:hover {
                color: white;
            }
        }
    }

    /* Footer terms section */
    &__terms {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        width: 100%;


        @media (max-width:$mobile-max-width) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
        }

        &-left {
            display: flex;
            align-items: center;
        }

        &-right {
            display: flex;
            
            gap: 15px;
            justify-content: flex-end;
            align-items: center;

            a {
                color: white;
                font-size: 13px;
                text-decoration: underline;

                &:hover {
                    color: white;
                }
            }
        }

        &-description {
            color: white;
            font-size: 13px;
        }
    }

    /* Social link icon styling */
    &__icon {
        margin-right: 5px;
        color: $primary-color;
    }



    .break-line{
        @media (max-width:$mobile-max-width) {
            display: none;
        }
    }

}


