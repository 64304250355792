// Breakpoints for Responsive Design

$mobile-max-width: 600px;

$tab-min-width: 601px;
$tab-max-width: 1024px;

$desktop-min-width: 1025px;
$desktop-max-width: 1700px;

$desktop-large-min-width: 1701px;
$desktop-large-max-width: 2000px;

$desktop-gaint-screen: 2001px;


// Font Sizes for Section Titles 

$section-title-mobile: 2rem;
$section-title-tab: 3.5rem;
$section-title-desktop-min: 3.75rem;
$section-title-desktop-large:   3vw;
$section-title-desktop-gaint: 3vw;


// Font Sizes for Section description
$description-mobile: 1rem;
$description-tab: 1rem;
$description-desktop-min: 1.1rem;
$description-desktop-large: 1.5rem;
$description-desktop-gaint: 2rem; 


// Font Sizes for Header and Footer

$header-mobile: 16px;
$header-tab: 18px;
$header-desktop-min: 18px;
$header-desktop-large: 18px;
$header-desktop-gaint: 20px;


// Font Sizes for slogan

$slogan-mobile: 16px;
$slogan-tab: 24px;
$slogan-desktop-min: 30px;
$slogan-desktop-large: 30px;
$slogan-desktop-gaint: 40px;


$title-fontsize-desktop:3vw;
$description-fontsize-desktop:1vw;

$section-margin-verticle: 3%;