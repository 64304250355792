@import '../../../constants/colorconstants.scss';
@import '../../../constants/constantsize.scss';

.popular-course-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin: $section-margin-verticle auto;
    @media screen and (max-width: $tab-max-width) {
        height: auto; /* Adjust height dynamically */
        width: 100%;
        background-color: #090909;
        flex-direction: column;
        justify-content: flex-start; /* Keep top alignment */
        padding: 20px 0; /* Add padding for better spacing */
    }
}

.popular-courses {
    width: 100%;
    background-color: #090909;
    // height: 430px;

    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-left: calc(10vw + 500px);
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    @media screen and (max-width: $tab-max-width) {
        padding-left: 0;
        justify-content: center;
        height: auto; /* Adjust height for better responsiveness */
    }
}

.course-content {
    margin-left: 8vw;
    position: absolute;
    border-radius: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 2vw;
    height: 400px;
    border-bottom: 1.5px solid $primary-color;

    @media (max-width:$tab-max-width) {

        border-bottom: none;

        
    }

    @media screen and (max-width: $tab-max-width) {
        margin-bottom: 20px;
        width: 90%; /* Adjust width for better fit */
        height: auto; /* Adjust height for better responsiveness */
        position: relative;
        margin-left: 0;
        background-color: #090909;
        border-radius: 0;
        color: white; /* Change text color */
        padding: 20px; /* Add padding for spacing */
        box-shadow: none; /* Remove shadow on mobile */
    }
    @media screen and (max-width:1200px) and (min-width:901) {
        padding-left: 0rem; /* Use padding instead of margin-left */
        
    }
}

.popular-course-card {
    width: 13vw;
    height: 150px;
    border-radius: 30px;
    background-color: #FFFEF6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    &:hover{
        box-shadow: 1px 0px 5px #FEFEFE 
    }
    @media screen and (max-width: $tab-max-width) {
        width: 25vw;
        height: 150px;
        border-radius: 20px;
    }
}

.course-content__description {

    font-size: $description-fontsize-desktop ;
    text-align: left;
    margin: 0;
    color: inherit; /* Inherit color from parent container for responsiveness */

    @media screen and (max-width: 650px) {
        font-size: 15px;
        text-align: center;
        color: white; /* Set color to white for small screens */
    }

    @media screen and (min-width: 651px) and (max-width: $tab-max-width) {
        font-size: 16px;
        color: white; /* Ensure it's white between 651px to 900px */
        text-align: center;
    }
}

.course-content__title {
    font-size: $title-fontsize-desktop;
    font-weight: bold;
    margin: 0;

    @media screen and (max-width: 650px) {
        font-size: 45px;
    }

    @media screen and (min-width: 651px) and (max-width: $tab-max-width) {
        font-size: 50px;
    }
}


.course-content__view-all-btn {
    background-color: $primary-color;
    color: white;
    width: 350px;
    height: 50px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    
    &:hover {
        background-color: darken($primary-color, 10%);
        transform: scale(1.05);
    }

    @media screen and (max-width: $tab-max-width) {
        display: none;
    }
}

.course-content__responsive-view-all-btn {
    display: none;

    &:hover {
        background-color: darken($primary-color, 10%);
        transform: scale(1.05);
    }

    @media screen and (max-width: $tab-max-width) {
        display: flex;
        background-color: $primary-color;
        color: white;
        width: 350px;
        height: 50px;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }
}

.populdar-course-container{

    background-color: black;
    width: 100%;
    padding-left: 43vw;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 20px;
    display: flex;
    flex-direction: column;

  
    @media (min-width: $desktop-min-width) and (max-width:1250px){  
        padding-left: 48vw;
    }

    @media (max-width:$tab-max-width) {
        width: auto;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 20px;
        align-items: center;
    }

  
}
.populdar-course-container__cards{

    display: flex;gap: 1vw;

    @media (max-width:$mobile-max-width) {
        gap: 10px;   
    }
}
.popular-course-card__title {
    font-size: 15px;
    font-weight: bold;
}

.popular-course-card__image {
    width: 50px;
    height: auto;
}
