@import '../../constants/colorconstants.scss';
@import '../../constants/constantsize.scss';


.testimonials__section {
    width: 90%;
    margin: auto;
    padding-top: 5px;
    position: relative;
// background-color: $primary-color;
}

.testimonials__card__left{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.testimonials__section__title {
font-weight: 600;
line-height: 70px;
text-align: left;
margin: 15px;


    padding-bottom: 25px;
}

@media (min-width: 1500px) {
    .testimonials__section__title {
        font-size: 2.8vw;  /* Relative to viewport width */
        line-height: 3.2vw; /* Maintain proportional line height */
    }
}

/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .testimonials__section__title {
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}
  
@media screen and (max-width:700px) {

    .testimonials__section__title {
        font-family: Inter;
        font-size: 32px;
        font-weight: 500;
        line-height: 38.4px;
        text-align: center;
        
    }}

.testimonials__carousel {
    position: relative;
    overflow: hidden;
}

.testimonials-coming-soon{

    font-size: 3.12vw;
    color: black;
}

.testimonials__card {
    height: auto;
    width: calc(100% - 6.5vw);
    background-color: white;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    gap: 30px;
    transition: transform 0.5s ease-in-out;
    border-bottom: 1.5px solid $primary-color;
}

@media (min-width: 1441px) {
    .testimonials__card {
        height: auto; 
    }
}


.testimonials__card__doublequote {
    width: 50px;
    height: auto;
    object-fit: contain;
}

.testimonials__card__left {
    padding-left: 10px;
    padding-right: 10px;
}

.testimonials__card__title {
    font-size: 2rem;
    color: black;
}

.testimonials__card__description {
    font-size: 1.5rem;
    color: black;
    margin: 0;
    text-align: start;
}

.testimonials__card__time {
    font-size: 0.8rem;
    color: black;
}

.testimonials__card__image {
    width: 350px;
    border-radius: 12px;
    // height: auto;
}

/* Centering custom controls */
.custom-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

/* Custom arrows with CSS icons */
.custom-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-arrow {
color: black;
    border: none;
    cursor: pointer;
    outline: none;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-arrow--prev::before,
.custom-arrow--next::before {
    content: '';
    display: block;
    width: 0; 
    height: 0; 
    border-style: solid;
}



.custom-dots {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    list-style: none;
    margin-left: 20px;
    margin-right: 20px;
}

.custom-dot {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: #E2E2E2;
    margin: 0 5px;
    cursor: pointer;
}

.custom-dot.active {
    background-color: black;
    width: 30px;
}


@media screen and (max-width:$tab-max-width) {
    .testimonials__section {
        
        margin: 0 auto;
    padding-top: 5px;
  
    position: relative;
    display: flex;
    justify-content: end; /* Horizontally center children */
    align-items: center;     /* Vertically center children */
    flex-direction: column;  /* Stack children vertically if needed */
    }
    .testimonials__card {
        padding: 0;
        width: 90vw;
        // height: 415px;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
        flex-direction: column-reverse;
        justify-content: space-evenly;
       
    }

    
    .testimonials__card__title {
        font-size:30px;
        color: black;
    }
    
    .testimonials__card__description {
        font-size: 12px;
        color: black;
    }

    .testimonials-coming-soon{

        font-size:20px;

    }
    .testimonials__card__image {
        width: 60%;
        border-radius: 12px;
        height: auto;

        @media (max-width:$mobile-max-width) {
            width: 90%;
            max-height: 350px;
            
        }
    }
    .testimonials__card__doublequote{
        width: 50px;
        height: auto;

    }.testimonials__card__right{
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .testimonials__card__left{

        padding-left: 5%;
        padding-right: 5%;
    }

}