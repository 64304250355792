@import './../../../constants/colorconstants.scss';
@import './../../../constants/constantsize.scss';
@import '../../../constants/fontsize_style.scss';
@import '../../../constants/animation_constants.scss';

.home-main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    background-image: url('../../../assets/images/HomePage/Hero-section-img.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media (min-width: $desktop-gaint-screen) {
        height: 29vw;
    }

    @media screen and (max-width: $mobile-max-width) {
        height: 500px;
        background-image: url('../../../assets/images/HomePage/Hero-section-responsive-image.webp');
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media screen and (max-width: $tab-max-width) and (min-width: $tab-min-width) {
        height: 500px;
        background-size: auto 100%; /* Fit the height, width scales proportionally */
        background-repeat: no-repeat; /* Prevent image repeat */
        background-position: center center; /* Center the image in the container */
    }

    &__title {
        font-weight: bold;
        color: white;
        margin-bottom: 1rem;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__description {
        color: white;
    }

    &__button {
        width: 200px;
        height: 50px;
        border-radius: 10px;
        background-color: $primary-color;
        text-align: center;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 10px;
        transition: background-color 0.3s ease, transform 0.3s ease;
        text-decoration: none;

        @media (min-width: $desktop-gaint-screen) {
            width: 10vw;
            height: 60px;
        }

        &:hover {
            background-color: darken($primary-color, 10%);
            transform: scale(1.05);
        }

        @media screen and (max-width: 600px) {
            width: 180px;
            height: 45px;
            font-size: 1rem;
        }
    }
}
