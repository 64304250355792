// Animations
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  20% {
    transform: translateY(-50%);
    opacity: 0; // Opacity remains 0 until 80%
  }
  100% {
    transform: translateY(0);
    opacity: 1; // Fade in from 80% to 100%
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes rotateTo90 {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(90deg)
  }
}

@keyframes rotateBackTo0 {
  0% {
    transform: rotate(90deg)
  }
  100% {
    transform: rotate(0deg)
  }
}

// Classes for applying animations
.scalInFromRight {
  animation: slideInFromRight 1.5s ease-out forwards;
}

.scalInFromLeft {
  animation: slideInFromLeft 1.5s ease-out forwards;
}

.scalInFromTop {
  animation: slideInFromTop 1.5s ease-out forwards;
}

.scalInFromBottom {
  animation: slideInFromBottom 1.5s ease-out forwards;
}

.rotateTo90Deg{
  animation: rotateTo90 1s ease both
}

.rotateBackTo0Deg{
  animation: rotateBackTo0 1s ease both
}