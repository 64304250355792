.section-wrapper {
  width: 90vw;
  margin: auto;

  @media (max-width: 1100px) {
    width: 90vw;
  }

  @media (max-width: 498px) {
    width: 93vw;
  }
//   @media (min-width: 768px) {
//     padding: 0 40px; // Increase padding for larger screens
//   }

//   @media (min-width: 1200px) {
//     padding: 0 60px; // Further increase padding for extra-large screens
//   }
}
