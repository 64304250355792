
@import '../../../../constants/fontsize_style.scss';

$borderRadius: 30px;
$responsiceBorderRadius: 20px;

.location-hours-container {
  background-color: #f5f5f5;
  margin: $section-margin-verticle auto;
  font-family: 'Mulish', sans-serif; 
}

.location-hours-content {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5%;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: normal;
  }

  &-text {
    flex: 1;
    padding: 2% 0;
    text-align: start;
    font-family: 'Mulish', sans-serif; // Apply Mulish if needed
  }

  .section-title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .section-description {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .info-cards {
    display: flex;
    justify-content: space-around;
    gap: 20px;

    .info-card {
      background-color: white;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 250px; 
      height: 200px;

      @media (min-width:$tab-min-width) and (max-width: $tab-max-width) {
        border-radius: $responsiceBorderRadius;
        width: 200px;
       height: 170px;
      }

      .info-icon {
        color: #8bc34a;
        font-size: 48px;

        @media screen and (max-width: 800px) {
          width: 50px;
          height: auto;
          object-fit: contain;
        }
      }

      .info-title {
        font-weight: bold;
        text-align: center;
        font-family: 'Mulish', sans-serif; // Apply Mulish for titles
        @media screen and (max-width: 800px) {
          font-size: 10px;
        }
      }
    }
  }
}
