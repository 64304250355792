/* PrivacyPolicy.scss */
.privacy-policy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.8; /* Increased line height for better readability */
}

.privacy-policy-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.privacy-policy-date {
  text-align: left;
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.policy-section {
  margin-bottom: 30px;
}

.section-title {
  font-size: 1.5rem;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: left;
}

.section-content {
  font-size: 1rem;
  color: #333;
  text-align: left;
  margin-bottom: 15px;
  line-height: 1.8; /* Increased line height for better readability */
}

.section-content br {
  display: block;
  margin: 10px 0;
}

strong {
  font-weight: bold;
  color: #000;
}
