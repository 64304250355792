@import '../../../../constants/fontsize_style.scss';
@import "../../../../constants/colorconstants.scss";
@import "../../../../constants/constantsize.scss";


$borderRadius: 40px;
$responsiveborderRadius: 20px;

.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  margin: $section-margin-verticle auto;
  font-family: "Mulish", sans-serif;

  @media (max-width: 498px) {
    padding: 5px;
    
  }

  .contactUsGif{

    height: 150px;
    width: 150px;
  }

  // @media (max-width:$tab-max-width) {
  //   margin-top: 15px;
  //   margin-bottom: 15px;
  // }
  .contact-form-content {
    display: flex;
    background-color: white;
    padding: 5%;
    border-radius: $borderRadius;
    box-shadow: 0px 1px 1px #8bc34a;
    max-width: 90vw;
    width: 100%;
    flex-direction: row;
    @media (max-width: 960px) {
      flex-direction: column;
      border-radius: $responsiveborderRadius;
      max-width: 90vw;
      gap: 20px;
      padding-bottom: 30px;
    }

    .form-heading {
      flex: 1.5;
      display: flex;
      align-items: flex-start;
      padding-top: 1%;
      padding-right: 1%;
      flex-direction: column;
      align-items: center;
      gap: 10;

      @media (max-width: 960px) {
        align-items: center;
      }

      // .form-title {
        // text-align: center;
        // font-weight: 600;
        // @media (max-width: 960px) {
        //   font-size: 2rem;
        // }
      // }
      .form-text {
        margin-right: 40%;
        text-align: left;
        @media (max-width: 960px) {
          text-align: center;
          margin-right: 0;
          margin: 0 10%;
          font-size: 0.8rem;
        }
      }
    }
   
    .form-fields {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .form-input {
        margin-bottom: 3%;

        input,
        textarea {
          background-color: #f5f5f5;
          border-radius: 4px;
        }
      }

      .form-submit-button {
        background-color: $primary-color;
        color: white;
        padding: 14px;
        border-radius: 8px;
        text-transform: none;
        font-size: 20px;

        @media (max-width: 960px) {
          padding: 12px;
          font-size: 16px;
        }

        &:hover {
          background-color: #7cb342;
        }
      }

      .custom-progress {
        margin: 0 1%;
        background-color: "#d9d9d9";
        .MuiLinearProgress-bar {
          background-color: #7cb342;
        }
      }
    }
  }
}
