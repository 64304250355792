

.course__page{

    background-color: #F5F5F5;
}

.course__description{

    font-size: 16px;
}