
@import '../../constants/constantsize.scss';
  
  .course-detail {
      margin : 5% 0;
      flex-wrap: wrap;
      padding: 40px;
      background-color: white;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 1px 1px #8bc34a;

    @media (max-width: 768px) {
      padding: 30px;
      margin-top: 30px;
    }

    &__top{
      display: flex;
    justify-content: space-between;
      align-items: start;
      text-align: left;
      justify-content: start;
      position: relative; 

      @media (max-width: 768px) {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column-reverse;
          justify-content: space-between;

  
        }
    
    }
      &__left {
        width: 60%;

        @media (max-width: 996px) {
          width: 100%;
        }

      }
    
      &__right {
        width: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
      
        
      }
    
      .course-title-wrapper{
        display: flex;
        justify-content: space-between;
      }

      .course-title {
        text-align: start;
       margin-top: 0;
      }

      .course-icon-responsive{
        display: none;

        @media (max-width: 996px) {
          display: block;
          width: 25%;
          aspect-ratio: 5/4;
          max-width: 100px;
        }
      }
    
      .syllabus-list {
        margin-top: 10px;
        padding-left: 20px; /* Adjusts indentation */
        list-style-type: disc; /* Adds bullet points */
        @media (max-width: 768px) {
          padding-left: 5px;
        }
      }
    
      .course-description,
      .course-prerequisites,
      .course-outcome {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 12px;
          margin-bottom: 8px;
        }

        span{
            font-weight: bold;
        }
      }
    
      .course-info {
        display: flex;
        margin-bottom: 20px;
      
        @media (max-width: 768px) {
          width: 100%;
          padding: 0;
        }
    
        p {
          font-size: 0.8rem;
          background-color: rgba(244, 244, 244, 1);
          margin-right: 20px;
          padding: 10px;
          border-radius: 10px;
          @media (max-width: 768px) {
            // font-size: 0.5rem;
            padding: 3% 2%;
            margin-right: 10px;
          }
        }
      }
    
      .demo-btn {
        background-color: rgba(118, 185, 0, 1);
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        width: 50%;
        height: 50px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .course-detail__right{


          img{
              width: 300px;
              height: auto;

              @media (max-width:$tab-max-width) and (min-width:$tab-min-width) {
              width: 250px;
              height: auto;
              margin-top: 30px;
              margin-bottom: 30px;
              }
              @media (max-width:$mobile-max-width)  {
              width: 200px;
              height: auto;


              margin-top: 30px;
              margin-bottom: 30px;
              }
          }
      }
    
      hr {
        margin: 40px 0;
        border: none;
        height: 1px;
        background-color: #ccc;
      }
    
      &__list {
        background-color: rgba(244, 244, 244, 1);
        padding: 10px 30px;
        text-align: left;
        border-radius: 20px;
        width: 60%;

        @media (max-width: 768px) {
          width: 80%;
        }
      
        ul {
          list-style-type: none;
          padding: 0;
    
          li {
            font-size: 1rem;
            margin-bottom: 20px;
            @media (max-width: 768px) {
              font-size: 0.8rem;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    

    