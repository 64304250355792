

@import '../../constants/fontsize_style.scss';
@import '../../constants/constantsize.scss';
.special-offer {
  background-color: rgba(118, 185, 0, 1);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5%;
  color: white;
  margin: $section-margin-verticle 0;

  
  @media (max-width: $mobile-max-width) {
    flex-direction: column;
    padding: 20px;
    align-items: end;
    padding: 5%;
  }

  &__content {
    flex: 1;
    text-align: left;
   
  }

  &__image {
    width: 25vw;
    display: flex;
    justify-content: flex-end;

    img {
      width: 200px;
      height: auto;

      @media (max-width: 468px) {
        width: 40%;
      }
    }
  }
}
