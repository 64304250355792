$search-height: 50px;
$border-radius-large: 50px;
$background-color: #ffffff;
$search-icon-size: 20px;
$search-icon-color: #888; // Adjust color as needed

.search-container {
  position: relative;
  width: 40%; // Adjust width for larger screens
  margin: 0 auto;

  @media screen and (max-width: 1256px) {
    width: 65%;
  }

  @media screen and (max-width: 768px) {
    width: 85%;
    margin: 0 auto; // Center align the search field horizontally
    display: flex; // Ensure it centers properly
    justify-content: center;
    align-items: center;
  }
}

.search-input {
  width: 100%; // Fill the width of the container
  height: $search-height;
  border-radius: $border-radius-large;
  background-color: $background-color;
  border: 1px solid #ddd;
  font-size: 1rem;
  color: #333;
  outline: none;

  @media screen and (max-width: 600px) {
    width: 100%; // Maintain full width within the container on mobile
  }

  &::placeholder {
    color: #aaa;
  }

  &:focus {
    border-color: darken(#ddd, 10%);
  }
}

// If the search icon is needed, uncomment the below code and ensure the icon aligns correctly.
.search-icon {
  // position: absolute;
  // right: 20px;
  // top: 50%;
  // transform: translateY(-50%);
  // width: $search-icon-size;
  // height: $search-icon-size;
  // background-image: url('/path/to/your/search-icon.svg'); // Replace with the path to your icon
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  // cursor: pointer;
}
