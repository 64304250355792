@import "../../../../constants/colorconstants.scss";

.form-title {
  text-align: left;
  font-weight: 800;
  padding: 1% 0;
  @media (max-width: 960px) {
    font-size: 2rem;
  }
}

.form-fields {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .form-input {
    margin-bottom: 3%;

    input,
    textarea {
      background-color: #f5f5f5;
      border-radius: 4px;
    }
  }

  .custom-select {
    border-radius: 8px;
    background-color: #f5f5f5;
    text-align: left;

    .MuiOutlinedInput-notchedOutline {
      border-color: #d9d9d9; // Match the initial border color
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #7cb342; // Change border color on hover
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #7cb342; // Border color when focused
    }

    .MuiSelect-icon {
      color: #7cb342; // Customize the dropdown arrow color
    }
  }

  .form-submit-button {
    background-color: $primary-color;
    color: white;
    padding: 14px;
    border-radius: 8px;
    text-transform: none;
    font-size: 20px;

    @media (max-width: 960px) {
      padding: 12px;
      font-size: 16px;
    }

    &:hover {
      background-color: #7cb342;
    }
  }

  .custom-progress {
    margin: 0 1%;
    background-color: "#d9d9d9";
    .MuiLinearProgress-bar {
      background-color: #7cb342;
    }
  }
}
