@import '../../../constants/colorconstants.scss';
@import '../../../constants/constantsize.scss';

@import '../../../constants/animation_constants.scss';

.learning-session {
    width: 90%;
    margin: $section-margin-verticle auto;
    display: flex;
    height: 500px;
    border-radius: 15px;
    position: relative;
    align-items: center;


    margin-top: 50px;
    margin-bottom: 30px;
   


    @media (max-width:$tab-max-width) and (min-width:$tab-min-width) {

        height: 450px;
        margin-top:0 ;
        margin-bottom:0 ;
        
    }

  

    &.alignComponentLeft {
        justify-content: start;
        @media screen and (max-width: 760px) {
            justify-content: center;
        }
    }

    &.alignComponentRight {
        justify-content: flex-end;

        @media screen and (max-width: 760px) {
            justify-content: start;
        }
    }


    @media screen and (max-width: 760px) {
        flex-direction: column;
        text-align: center;
        height: 550px;
        width: 100vw; 
        margin-bottom: 40px;
        align-items: center;
        justify-content: start;
        &.normal {
            flex-direction: column;
        }
    }
    @media (max-width:549px) {
        height: 600px;
        
    }

    &__image {
        display: flex;
        border-radius: 15px;
        width: 400px;
          height: auto;
        object-fit: cover;

        @media screen and (max-width: 760px) {
            width: 250px;
            height:auto;
        }
        @media screen and (min-width: 761px) and (max-width: $tab-max-width)  {
            width: 300px;
            height: auto;
        }
    }


    &__content {
        z-index: -1;
        position: absolute;
        background-color: white;
        height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 40px;
        text-align: left;
        
        border-bottom: 1.5px solid $primary-color;
        @media screen and (max-width: 760px) {
            width: 90vw;
            padding: 20px;
            margin-top: 80px;   
            height: 350px;
            justify-content: end;
    
              left: 0; 
        right: 0;
        margin-left: auto; 
         margin-right: auto; 
      padding: 30px;
            gap: 5px;
      
        }
        &.normal {
            left: 200px;
            padding-left: 250px;
            padding-right: 100px;



            @media screen and (min-width: 761px) and (max-width: $tab-max-width)  {
                left: 200px;
            padding-left: 150px;
            padding-right: 50px;
            height: 400px;

            }



            @media (min-width:511px) and (max-width: 760px) {
                left: 0;
                padding: 20px;
                height: 350px;
                text-align: center;
                width: 82vw;
                height: 400px;
                justify-content: end; 
              gap: 5px;
 
            }
            @media (max-width: 510px) {
                left: 0;
                padding: 20px;
                height: 350px;
                text-align: center;
                width: 82vw;
                height: 460px;
                justify-content: end; 
              gap: 5px;
 
            }
        }

    
           
    
            &.reverse {
               
                right: 200px;
                padding-right: 250px;
                padding-left: 100px;

                @media screen and (min-width: 761px) and (max-width: $tab-max-width)  {
                    right: 170px;
                    padding-right: 150px;
                    padding-left: 50px;
                    height: 400px;
    
                }
    
            @media (min-width:511px) and (max-width: 760px) {
                right: 0;
                padding: 20px;
                height: 350px;
                text-align: center;
                width: 82vw;
                height: 400px;
                justify-content: end; 
                  gap: 5px;
                  
            }
            @media (max-width: 510px) {
                right: 0;
                padding: 20px;
                height: 350px;
                text-align: center;
                width: 82vw;
   
                justify-content: end; 
                  gap: 5px;
                height: 470px;
                  
            }
               
            
            }
       
    }

    &__tagline {
        font-size: 1rem;
        color: #8bc34a; /* Adjust the color as needed */
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__title {
        font-size: $title-fontsize-desktop;
        color: #000; // Main title color
        font-weight: bold;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 10px;

        @media screen and (max-width: 760px) {
            font-size: 1.5rem;
            margin: 0;
        }
        @media screen and (min-width: 761px) and (max-width: $tab-max-width) {
            font-size: 2rem;
            margin: 0;
        }
    }
    &__description {
        font-size: $description-fontsize-desktop;
        color: #555; /* Adjust the color as needed */
        line-height: 1.5;
    }
}
