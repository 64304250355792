/* TermsAndConditions.scss */
.terms-and-conditions-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .terms-and-conditions-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .terms-and-conditions-date {
    text-align: left;
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .terms-section {
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 1.5rem;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: left;
  }
  
  .section-content {
    font-size: 1rem;
    color: #333;
    text-align: left;
    margin-bottom: 15px;
  }
  
  .section-content ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .section-content strong {
    font-weight: bold;
    color: #000;
  }
  
  .section-content br {
    display: block;
    margin: 10px 0;
  }
  