@import '../../constants/constantsize.scss';


@import '../../constants/fontsize_style.scss';

$padding-standard: 30px;

.header-content {
    width: calc(100% - 10rem);
    margin: $section-margin-verticle auto;
    border-radius: 40px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;

    &--normal {
        flex-direction: row; 
        justify-content: flex-end; 

        @media (max-width : $tab-max-width) {
            
            flex-direction: column-reverse; 
        }
    }

    &--reverse {
        flex-direction: row-reverse; 
        justify-content: start; 
        @media (max-width : $tab-max-width) {
            flex-direction: column-reverse; 
        }
    }



    @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
        //  margin: 30px
        
    }

    &__left {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    // &__title {
    //     font-size: 3.75rem;
    //     font-weight: 600;
    //     margin-bottom: 15px;
    //     width: 100%;
    //     @media (min-width:$tab-min-width)  and (max-width: $tab-max-width) {
    //         font-size: 3.5rem;
    //     }
    //     @media (max-width: $mobile-max-width) {
    //         font-size: 2.5rem;
    //     }
    //     @media (min-width:$desktop-gaint-screen) {

    //         font-size: 3vw;
    //    }
    // }

    &__description {    
    max-width: 600px; 
    }

    &__right {
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;

     
    }

    &__image {
        width: 160px;
        height: 160px;
        object-fit: contain;

        @media (max-width:$mobile-max-width) {
            width: 120px;
            height: 120px;
        }
        @media (max-width:$tab-max-width) and (min-width:$tab-min-width) {
            width: 140px;
            height: 140px;
        }
    }
}

