@import "../../../constants/colorconstants.scss";
@import "../../../constants/constantsize.scss";

.whyZenithReasonsSection {
  background-color: $primary-color;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 40px;
  padding-bottom: 90px;

  @media screen and (min-width: 601px) and (max-width: 900px) {
    padding-top: 20px;
    padding-bottom: 50px;
  }

  &__card {
    margin-top: 10px;
    margin-bottom: 10px;

    width: 50%;
    border: 1px dashed white;
    border-radius: 10px;
    display: flex;
    justify-content: end;

    padding: 20px 40px;

    @media screen and (min-width: 601px) and (max-width: 900px) {
      width: 70%;
    }
    @media screen and (max-width: 600px) {
      width: 90%;
      padding: 20px 10px;
    }

    &__right {
      text-align: start;

      width: 70%;
    }

    &__left {
      width: 30%;
      align-items: center;
      justify-content: center;
      display: flex;
      &__icon-container {
        background-color: white;
        border-radius: 60px;
      }
      &__icon {
        width: 50px;
        height: 50px;
        padding: 20px;
      }
    }
  }
  .hiddenAnimation {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
}
