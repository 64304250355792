.carousel-container {
    background-color: #75b500; 
    padding: 60px 20px; 
    text-align: center;

    @media (min-width: 768px) {
        display: none;
      }
  
    .carousel-title {
      font-size: 36px; 
      color: white; 
      margin-bottom: 30px; 
    }
    .quote-mark {
     
        width: 100px;
      height: 100px;
     
      }
  
    .slick-dots {
      display: flex;
      justify-content: center;
      margin-top: 20px;
  
      li {
        width: 20px;
        height: 2px;
        background-color: white;
        margin: 0 5px;
  
        &.slick-active {
          background-color: #fff; 
          width: 40px;
        }
  
        button {
          opacity: 0;
        }
      }
    }

    .slick-slide {
        padding: 0px 45px; 
        box-sizing: border-box;
       
      }
  
    .testimonial {
     display: flex;
     justify-content: flex-start;
      border: 2px solid white;
      padding: 20px;
      border-radius: 20px;
    margin-top: 20px;
    margin-left: -20px;
  
      .quote {
        font-size: 16px;
        color: white; 
        margin: 20px 0;
        text-align: left;
        line-height: 1.5; 
      }
  
      .author {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        color: white;
        text-align: left;
       
      }
    }
  }
  