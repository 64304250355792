
@import '../../../constants/colorconstants.scss';
@import '../../../constants/constantsize.scss';
.whyZenithMission{

    display: flex;

    justify-content: start;
    align-items: start;
    flex-direction: column;
    
    border-radius: 12px;
    width: 48%;
    background-color: white;
    border-bottom: 1.5px solid $primary-color;
    text-align: left;


    padding:20px 2vw;
    @media screen and (max-width: $tab-max-width) {
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        width: auto;
        text-align: center;
      }
    &__title{
        color: black;
        margin-top: 0;
    }

}