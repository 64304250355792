@import '../../../constants/colorconstants.scss';
@import '../../../constants/constantsize.scss';

$container-margin: 3rem;
$component-margin: 20px;

$border-radius: 10px;
$font-color-dark: #000;
$font-size-large: 40px;
$font-size-medium: 25px;
$font-size-small: 22px;
$padding-standard: 25px;

.courses {
  background-color: white;
  width: 90%;
  margin: $section-margin-verticle auto;
  padding: $padding-standard;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 600px) {
    margin: $section-margin-verticle auto;
    padding: 20px;
    width: calc(90% - 20px);
    border-radius: 10px;
  
  }

  @media (min-width: $tab-min-width) and (max-width: $tab-max-width) {
    margin: $section-margin-verticle auto;
    padding: 10px;
    width: calc(90% - 10px);
    border-radius: 10px;
  }

  &__title {
    margin: 0 $component-margin;
    color: $primary-color;
    font-size: 3vw;
    overflow: hidden;
    text-overflow: ellipsis;
    

    @media screen and (max-width: $mobile-max-width) {
      margin-left: 10px;
      margin-right: 10px;
      text-align: center;
    }

    @media screen and (min-width: $tab-min-width) and (max-width: $tab-max-width) {
      // margin: 20px;
      text-align: center;
    }
  }

  &__description {
    margin: 20px $component-margin;
    color: $font-color-dark;
    text-align: left;

    @media screen and (max-width: $mobile-max-width) {
      margin: 15px 10px;
      text-align: center;
    }

    @media screen and (min-width: $tab-min-width) and (max-width: $tab-max-width) {
      margin: 10px 20px;
      text-align: center;
    }
  }

  &__grid {
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(3, 1fr); 
    row-gap: 20px; 
    align-items: center;
    margin-top: 30px;
  
    @media screen and (max-width: $tab-max-width) {
      grid-template-columns: repeat(2, 1fr); 

    }
  
    @media screen and (max-width: $mobile-max-width) {
      grid-template-columns: 1fr; 
      
    }
  }
 
}

.course-card {

  height: 400px;
  width: 22vw;
  border-radius: $border-radius;
  border: 1.5px solid $font-color-dark;
  text-align: left;
  padding: $padding-standard;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: $tab-max-width) {
    width: 80%;

  }

  @media screen and (max-width: $mobile-max-width) {
    margin: 0;
    height: 400px;
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  }

  &__header {
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: $mobile-max-width) {
      width: 70%;
      height: 40%;
    }

    @media screen and (min-width: $tab-min-width) and (max-width: $tab-max-width) {
      height: 50%;
    }
  }

  &__image {
    height: auto;
    width: 50%;
    object-fit: contain;
  }

  &__body {
    height: 50%;
  }

  &__title {
    font-size: $font-size-medium;
    color: $font-color-dark;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: $tab-min-width) and (max-width: $tab-max-width) {
      font-size: $font-size-small;
    }
  }

  &__description {
    margin: 10px 0;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: $tab-min-width) and (max-width: $tab-max-width) {
      font-size: 16px;
    }
  }
}
