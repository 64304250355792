@import '../../constants/fontsize_style.scss';
@import '../../constants/constantsize.scss';

$highlight-color: rgba(118, 185, 0, 1);
$card-bg-color: rgba(40, 40, 40, 1);
$white-color: rgba(255, 255, 255, 1);
$padding-mobile: 20px;
$border-radius-mobile: 20px;
$mobile-width: 480px;

.about-us-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $section-margin-verticle auto;
   
  h1 {
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.highlight-section {
  margin: $section-margin-verticle auto;
  background-color: $highlight-color;
  height: 278px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1200px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-width: 700px) {
    height: auto;
    margin-top: 0;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    margin: 10px auto;
    padding: 0 20px;
    gap: 20px;

    @media (max-width: $tab-max-width) {
      text-align: center;
      gap: 10px;
    }

    @media (max-width: 700px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  p {
    color: white;
  }

  .images {
    display: flex;
    gap: 20px;

    @media (max-width: $tab-max-width) {
      gap: 10px;
      justify-content: center;
    }

    img {
      height: 390.69px;
      width: 176.83px;
      object-fit: cover;
      border-radius: 20px;

      @media (max-width: $tab-max-width) {
        height: 280px;
        width: 105px;
      }

      @media (max-width: $mobile-width) {
        height: 200px;
        width: 100px;
      }
    }
  }
}

.youtube {
  background-color: $white-color;
  border-radius: 40px;
  margin: 7% auto $section-margin-verticle auto ;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 500px;

  @media (max-width: $tab-max-width) {
    width: 85%;
    padding: 30px;
    height: 300px;
  }

  @media (max-width: $mobile-max-width) {
    width: 90%;
    padding: 20px 10px;
    height: 300px;
    margin: $section-margin-verticle auto;
    border-radius: $border-radius-mobile;
  }

  iframe {
    width: 90%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

.tutors-section {
  background-color: $white-color;
  border-radius: 40px;
  margin: $section-margin-verticle auto;
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px 40px;
  border-bottom: 1px solid $highlight-color;
  box-sizing: border-box;
  width: 90%;
  height: fit-content;

  @media (max-width: $tab-max-width) {
    width: 90%;
    padding: 20px;
  }

  @media (max-width: $mobile-max-width) {
    width: 90%;
    border-radius: $border-radius-mobile;
    margin: $section-margin-verticle auto;
  }

  h2 {
    text-align: left;
    font-weight: 700;

  }

  .cards {
    justify-content: center;

    @media (max-width: 1400px) {
      gap: 20px;
    }
  }

  .card {
    background-color: $card-bg-color;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    gap: 30px;
    align-items: center;
    margin: 20px 0;

    @media (max-width: $mobile-max-width) {
      flex-direction: column-reverse;
    }

    .text {
      color: white;
      display: flex;
      flex-direction: column;
      text-align: left;

      @media (max-width: $mobile-max-width) {
        text-align: center;
      }

      h3 {
        margin: 0;
        color: white;
        font-weight: 600;
        font-size: 22px;

        @media (max-width: $mobile-width) {
          font-size: 20px;
        }
      }

      p {
        font-weight: 700;
        color: $highlight-color;

        @media (max-width: $mobile-width) {
          font-size: 20px;
          text-align: center;
        }
      }
    }

    .tutor-image {
      max-width: 400px;
      max-height: 400px;
      min-width: 300px;
      min-height: 300px;
      height: auto;
      width: auto;
      object-fit: cover;
      border-radius: 10px;

      @media (max-width: $tab-max-width) {
        max-width: 250px;
        max-height: 250px;
        min-width: 200px;
        min-height: 200px;
        height: auto;
      width: auto;
      }

      @media (max-width: $mobile-max-width) {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
}
